import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";

import nftAbi from '../../../constants/nft.abi.json'
import { ethers } from "ethers";
import { notification } from "antd";

const NFT_CONTRACT_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_NFT_ADDRESS + '')

const useNftsBalance = () => {
    const { account, library } = useWeb3React()
    const [nftsBalance, setNftsBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    const getBalance = useCallback(async () => {
        if (!account || !library) return
        const signer = library.getSigner()
        const nftContract = new ethers.Contract(NFT_CONTRACT_ADDRESS, nftAbi, signer)
        try {
            setLoading(true)
            const tx = await nftContract.balanceOf(account)
            setNftsBalance(Number(tx.toString()))
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setNftsBalance(0)
            notification.error({
                message: error?.message || error
            })
        }
    }, [account, library])
    useEffect(() => {
        if (!account || !library) return
        getBalance()
    }, [account, library])

    return nftsBalance
}

export default useNftsBalance;