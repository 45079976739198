import React from 'react'
import { Button, Col, Divider, Form, FormInstance, Image, Input, notification, Popconfirm, Row, Space, Typography } from "antd"
import { CalendarOutlined, HomeOutlined, HeartOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import './index.less'
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { create } from '../../../actions/payment'
import PaymentService from '../../../services/payment'
import product from '../../../product.png'
import { useQuery } from "../../../Hook/query";
import RoomComp from "./Room";
import usePrice from "../Hooks/usePrice";
import { useNftDetail } from "../Hooks/useNftAmount";
import useNftsBalance from "../Hooks/useNftsBalance";
import { sign } from "../../../helpers/crypto";
import Utils from '../../../Pages/Rooms/Utils';
import { getImageUrl } from '../../../helpers/url';
import { searchRoom } from '../../../actions/room';
import { RoomBooked, Room } from '../../../Types/Room';
import OrderInfoWrapper from './OrderInfo';
import useBook from '../Hooks/useBook';
import useApprovedForAll from '../Hooks/useApprovedForAll';
import Voucher from './Voucher';

const { Text, Title } = Typography

const RoomName = (props: any) => {
    return <Text
        strong
        style={{
            fontSize: 16,
        }}
        ellipsis={{ tooltip: props.text }}
    >
        {props.text}
    </Text>
}

const PaymentGateway = () => {
    const navigate = useNavigate()
    const { params: {
        adult, children, checkIn, checkOut, resort
    } } = useQuery()

    const [loading, setLoading] = useState(false)
    const [voucher, setVoucher] = useState<any>()
    const [form] = Form.useForm();

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const rooms: RoomBooked[] = useSelector((state: any) => state.reservation.rooms)
    const roomsInfo: Room[] = useSelector((state: any) => state?.room?.data || [])
    const error = useSelector((state: any) => state.reservation.error)
    const nfts: any[] = useSelector((state: any) => state.wallet?.nfts || [])

    const [selectedKey, setSelectedKey] = useState(rooms[0]?._id)

    const { checkInMoment, checkOutMoment } = useMemo(() => ({
        checkInMoment: moment(Number(checkIn)),
        checkOutMoment: moment(Number(checkOut))
    }), [checkIn, checkOut])
    const priceDetail = usePrice(rooms, Number(checkIn), Number(checkOut))
    const nftDetails = useNftDetail(rooms, Number(checkIn), Number(checkOut))

    const totalAmountRoom = useMemo(() => {
        if (!rooms?.length) return 0
        return rooms.reduce((ret: number, room: any) => ret + Number(room.amount), 0)
    }, [rooms])

    const nftIds = useMemo(() => {
        return nfts?.slice(0, nftDetails.totalPrice)
    }, [nfts, nftDetails.totalPrice])

    const oncCreatePaymentSuccess = useCallback(({ orderId }) => {
        // eslint-disable-next-line no-restricted-globals
        navigate(`/payment/ipn?orderId=${orderId}`)
        form.resetFields();
    }, [form, navigate])
    const oncCreatePaymentSuccessWithMomo = useCallback((url) => {
        // eslint-disable-next-line no-restricted-globals
        location.href = url
        form.resetFields();
    }, [form])

    const { book, setLoadingBook, bookLoading } = useBook();

    const onCreatePaymentFail = useCallback((message) => {
        setLoadingBook(false)
        setLoading(false)
        // Update latest price
        dispatch(searchRoom({
            resort,
            adult,
            checkIn,
            checkOut
        }))
        notification.error({
            message
        })
    }, [adult, checkIn, checkOut, resort, dispatch, setLoadingBook])


    const onSubmitWithVoucher = useCallback(async (values: any) => {
        if (!voucher || !voucher?.ids?.length) {
            return notification.error({
                message: "Not enough nfts"
            })
        }
        const orderId = moment().valueOf()
        // Trim space
        for (const key in values) {
            values[key] = values[key]?.trim()
        }
        const body: any = {
            invoice: {
                ...values,
                children, adult,
                nft: true,
                voucher: true,
                signature: voucher.signature,
                messageHash: voucher.messageHash
            },
            checkIn,
            checkOut,
            rooms: rooms.map((r: any) => ({
                roomId: r._id,
                amount: r.amount
            })),
            totalPrice: Number(nftDetails.totalPrice),
            orderId
        }
        let secureHash = sign(body)
        body.secureHash = secureHash
        setLoadingBook(true)
        dispatch(create(body, async (data: any) => {
            try {
                setLoadingBook(false)
                navigate(`/payment/ipn?transactionHash=${data.transactionHash}`)
            } catch (error: any) {
                setLoadingBook(false)
                notification.error({
                    message: error?.message || error
                })
            }
        }, onCreatePaymentFail))

    }, [voucher, adult, children, checkIn, nftDetails, rooms, checkOut, setLoadingBook, onCreatePaymentFail, dispatch, navigate])

    const onSubmitNft = useCallback(async (values: any) => {
        if (!nftIds.length) return notification.error({
            message: "Not enough nfts"
        })
        try {
            const orderId = moment().valueOf()
            // Trim space
            for (const key in values) {
                values[key] = values[key]?.trim()
            }
            setLoadingBook(true)
            const body: any = {
                invoice: { ...values, children, adult, nft: true },
                checkIn,
                checkOut,
                rooms: rooms.map((r: any) => ({
                    roomId: r._id,
                    amount: r.amount
                })),
                totalPrice: nftIds.length,
                orderId
            }
            let secureHash = sign(body)
            body.secureHash = secureHash
            dispatch(create(body, async () => {
                try {
                    const tx = await book(nftIds, orderId)
                    const txHash = tx.hash
                    await tx.wait(1)
                    setLoadingBook(false)
                    navigate(`/payment/ipn?transactionHash=${txHash}`)
                } catch (error: any) {
                    console.log('herrrrreeeeee')
                    setLoadingBook(false)
                    notification.error({
                        message: error?.message || error
                    })
                }
            }, onCreatePaymentFail))
        } catch (error: any) {
            setLoadingBook(false)
            notification.error({
                message: error?.message || error
            })
        }

    }, [book, nftIds, setLoadingBook, adult, checkIn, checkOut, children, dispatch, navigate, onCreatePaymentFail, rooms])

    const onValidateSuccess = useCallback((values: any) => {
        if (process.env.REACT_APP_NFT) {
            if (voucher) return onSubmitWithVoucher(values)
            return onSubmitNft(values)
        }
        setLoading(true)
        let amount = parseInt(priceDetail.totalPrice)

        // Trim space
        for (const key in values) {
            values[key] = values[key]?.trim()
        }

        const body: any = {
            invoice: { ...values, children, adult },
            checkIn,
            checkOut,
            rooms: rooms.map((r: any) => ({
                roomId: r._id,
                amount: r.amount
            })),
            totalPrice: amount
        }

        PaymentService.getPaymentUrl({
            orderDescription: values.message,
            amount
        }).then(({ url, orderId }) => {
            body.orderId = orderId
            let secureHash = sign(body)
            body.secureHash = secureHash
            if (!process.env.REACT_APP_USE_MOMO) {
                return dispatch(create(body, () => oncCreatePaymentSuccess(body), onCreatePaymentFail))
            }
            return dispatch(create(body, () => oncCreatePaymentSuccessWithMomo(url), onCreatePaymentFail))

        }).catch(error => {
            onCreatePaymentFail(error?.message)
        })

    }, [checkIn, checkOut, oncCreatePaymentSuccess, onCreatePaymentFail, dispatch, adult, voucher, oncCreatePaymentSuccessWithMomo, onSubmitNft, children, rooms, priceDetail, onSubmitWithVoucher])

    const onValidateFail = (errorInfo: any) => {
    };
    const getRoomImage = useCallback((imgs = []) => {
        if (imgs.length) {
            return getImageUrl(imgs[0])
        }
        return product
    }, [])
    const handleOnVoucher = useCallback((messageHash, signature, ids) => {
        setVoucher({
            messageHash,
            signature,
            ids
        })
    }, [])
    const handleRemoveVoucher = useCallback(() => {
        setVoucher(null)
    }, [])
    useEffect(() => {
        console.info('Init PaymentGateway')
        dispatch(searchRoom({
            resort,
            adult,
            checkIn,
            checkOut
        }))
    }, [])
    if (error) {
        return <Space>
            <Title>oops something went wrong</Title>
        </Space>
    }
    return <Row style={{
        marginTop: 64
    }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
            <Title level={3}>{t('payment.title')}</Title>
            <Divider />
            <Space style={{
                width: '100%',
                padding: 16
            }} direction='vertical'>
                <Form
                    form={form}
                    layout="vertical"
                    name="payment"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onValidateSuccess}
                    onFinishFailed={onValidateFail}
                    autoComplete="off"
                >
                    <Form.Item
                        label={t('payment.fullname')}
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: t('payment.error.fullnameRequired')
                            }
                        ]}
                    >
                        <Input className="heron-payment-gateway-input" />
                    </Form.Item>
                    <Form.Item
                        label={t('payment.email')}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: t('payment.error.emailInvalid')
                            },
                            {
                                required: true,
                                message: t('payment.error.emailRequired')
                            },

                        ]}
                    >
                        <Input
                            className="heron-payment-gateway-input"
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('payment.phone')}
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: t('payment.error.phoneRequired')
                            },
                            {
                                message: t('payment.error.phoneInvalid'),
                                pattern: new RegExp(/^[0-9]+$/)
                            }
                        ]}
                    >
                        <Input className="heron-payment-gateway-input" />
                    </Form.Item>
                    <Form.Item
                        label={t('payment.otherRequirement')}
                        name="message"
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    {process.env.REACT_APP_NFT && <Voucher onRemove={handleRemoveVoucher} onVoucher={handleOnVoucher} />}
                    <Form.Item >
                        {
                            process.env.REACT_APP_NFT ? voucher ? <ButtonConfirmWithVoucher form={form} disabled={false} loading={loading || bookLoading} totalNft={nftDetails.totalPrice}  {...voucher} /> : <ButtonConfirm form={form} disabled={false} loading={loading || bookLoading} totalNft={nftDetails.totalPrice} /> : <Button shape='round' type="primary" htmlType="submit" loading={loading}>
                                {t('payment.confirm')}
                            </Button>
                        }

                    </Form.Item>
                </Form>
                <Text>
                    {t('message.confirmNoteMessagePart1')} {t('message.confirmNoteMessagePart2')}
                </Text>

            </Space>
        </Col>
        <Col span={1}></Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Space className='width-100'
                size={24} direction='vertical'>
                <RoomComp setSelectedKey={setSelectedKey} selectedKey={selectedKey} rooms={rooms} totalAmountRoom={totalAmountRoom} />
                <Space className="heron-payment-gateway-container" size={16} direction='vertical'>
                    <Row className="room-detail">
                        <Col span={11}>
                            <Image preview={false} src={getRoomImage(roomsInfo.find((r: any) => r._id.toString() == selectedKey)?.imgs)} />
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                            <RoomName text={roomsInfo.find((r: any) => r._id.toString() == selectedKey)?.name} />
                            <Utils utils={roomsInfo.find((r: any) => r._id.toString() == selectedKey)?.utils || []} />

                            <Divider />
                        </Col>
                    </Row>
                    <Space className="heron-order-gateway-atribute" direction='vertical'>
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Space className="heron-reservation-atribute-item">
                                    <CalendarOutlined className="heron-payment-gateway-atribute-icon" />
                                    <Space direction='vertical' size={2}>
                                        <Text disabled className='font-12'>{t('common.checkIn')}</Text>
                                        <Text strong>{moment(checkInMoment).format('L')}</Text>
                                    </Space>
                                </Space>
                            </Col>
                            <Col span={2}>
                                <Divider type="vertical" />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Space className="heron-reservation-atribute-item">
                                    <CalendarOutlined className="heron-payment-gateway-atribute-icon" />
                                    <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                        <Text disabled className='font-12'>{t('common.checkOut')}</Text>
                                        <Text strong>{moment(checkOutMoment).format('L')}</Text>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Space className="heron-reservation-atribute-item">
                                    <HeartOutlined className="heron-payment-gateway-atribute-icon" />
                                    <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                        <Text disabled className='font-12'>{t('common.adult')}</Text>
                                        <Text strong>{adult}</Text>
                                    </Space>
                                </Space>
                            </Col>
                            <Col span={2}>
                                <Divider type="vertical" />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Space className="heron-reservation-atribute-item">
                                    <HomeOutlined className="heron-payment-gateway-atribute-icon" />
                                    <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                        <Text disabled className='font-12'>{t('common.children')}</Text>
                                        <Text strong>{children}</Text>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Space className="heron-reservation-atribute-item">
                                    <HeartOutlined className="heron-payment-gateway-atribute-icon" />
                                    <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                        <Text disabled className='font-12'>{t('common.roomAmounts')}</Text>
                                        <Text strong>{totalAmountRoom}</Text>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                    </Space>

                    <Text strong style={{
                        fontSize: 18
                    }}>{t('payment.priceDetail')}</Text>
                    <OrderInfoWrapper priceDetails={priceDetail} nftDetails={nftDetails} />
                </Space>
            </Space>
        </Col>
    </Row>
}
type ButtonConfirmProps = {
    disabled?: boolean,
    loading?: boolean,
    totalNft: number,
    form: FormInstance<any>

}
type ButtonConfirmWithVoucherProps = {
    disabled?: boolean,
    loading?: boolean,
    totalNft: number,
    ids: number[],
    messageHash: string,
    signature: string,
    form: FormInstance<any>

}
const ButtonConfirm = (props: ButtonConfirmProps) => {
    const loading = useSelector((state: any) => state.wallet?.loading)
    const nfts: any[] = useSelector((state: any) => state.wallet?.nfts || [])
    const { t } = useTranslation()
    const { isApprovedForAll, approve, approveLoading } = useApprovedForAll();
    const nftsBalance = useNftsBalance()

    if (!isApprovedForAll) {
        return <Button
            onClick={approve}
            loading={approveLoading}
            disabled={isApprovedForAll}
            shape='round'
            type='primary'
        >
            Approve
        </Button>
    }

    return <Popconfirm
        placement="top"
        title={"Your booking will not be amended or canceled. Proceed?"}
        okText="Yes"
        cancelText="No"
        onConfirm={props.form.submit}
    >
        <Button
            // htmlType="submit"
            loading={loading || props.loading}
            disabled={props.disabled || props.totalNft > nftsBalance}
            shape='round'
            type='primary'
        >
            {props.totalNft > nftsBalance ? `Insufficient nft amount` : t('payment.confirmAndPay') + `(You have ${nftsBalance} nfts)`}
        </Button>
    </Popconfirm>
}
const ButtonConfirmWithVoucher = (props: ButtonConfirmWithVoucherProps) => {
    const { t } = useTranslation()

    return <Popconfirm
        placement="top"
        title={"Your booking will not be amended or canceled. Proceed?"}
        okText="Yes"
        cancelText="No"
        onConfirm={props.form.submit}
    >
        <Button
            htmlType="submit"
            loading={props.loading}
            disabled={props.disabled || props.totalNft > props.ids.length}
            shape='round'
            type='primary'
        >
            {props.totalNft > props.ids.length ? "Insufficient nft amount" : t('payment.confirmAndPay') + `(You have ${props.ids.length} nfts)`}
        </Button>
    </Popconfirm>
}
export default PaymentGateway;