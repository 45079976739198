import { useWeb3React } from "@web3-react/core"
import { useCallback, useState } from "react"
import { useContractSigner } from "../../../Hook/useContract"
import HeronNft2023Abi from '../../../constants/heronnft2023.abi.json'
import { notification } from "antd"
import { isAddress } from "ethers/lib/utils"
import { useDispatch } from "react-redux"
import { TRANSFER_NFT_FAIL, TRANSFER_NFT_SUCCESS, TRANSFER_NFT_START } from "../../../actions/types"
import { BigNumber } from "ethers"

export const useTransferNft = (
  contractAddress: string
) => {
  const { account, library } = useWeb3React()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const HeronNft2023ContractSigner = useContractSigner(contractAddress, HeronNft2023Abi)
  const transfer = useCallback(async ({
    to,
    tokenId
  }
    : {
      to: string
      tokenId: number
    }
  ) => {

    if (!account || !library || !HeronNft2023ContractSigner) {
      notification.error({
        message: "Please connect to wallet"
      })
      return Promise.reject('Missing account or nftContract')
    }

    if (!isAddress(to)) {
      notification.error({
        message: "Invalidate Address"
      })
      return Promise.reject('Invalidate Address')
    }


    try {
      setLoading(true)
      dispatch({
        type: TRANSFER_NFT_START,
        payload: null
      })
      const tx = await HeronNft2023ContractSigner["safeTransferFrom(address,address,uint256)"](account, to, tokenId);
      await tx.wait(1)
      dispatch({
        type: TRANSFER_NFT_SUCCESS,
        payload: {
          tx,
          tokenId
        }
      })
      dispatch({ type: 'TRANSFER_EVENT', payload: {
        tokenId : BigNumber.from(tokenId),
      } })
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      dispatch({
        type: TRANSFER_NFT_FAIL,
        payload: error
      })
      console.log(error)
      notification.error({
        message: error?.message || error?.error.message || error
      })
    }
  }, [account, library, HeronNft2023ContractSigner])
  return { transfer, isTransferring: loading }
}