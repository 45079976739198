import { useWeb3React } from "@web3-react/core";
import {
  Button,
  Col,
  Input,
  Layout,
  Popover,
  Row,
  Spin,
  Typography,
  Modal,
  Space,
} from "antd";
import { getAddress, isAddress } from "ethers/lib/utils";
import { add } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDebounce } from "../../Hook/useDebounce";
import { useMulticallClaimInfo } from "../../Hook/useMulticallClaimInfo";
import { useClaimNft } from "./hooks/useClaimNft";
import { useGetNftOfWallet } from "./hooks/useGetListNftOfWallet";
import {
  useGetListTokenOfNft,
  useGetListTokenOfNft2023,
  useGetListTokenOfNft2023SwrInfinite,
  useGetListTokenOfNftSwrInfinite,
} from "./hooks/useGetListTokenOfNft";
import { useGetListTokenOfNfts } from "./hooks/useGetListTokenOfNfts";
import { useListenClaimEvent } from "./hooks/useListenClaimEvent";
import { useListenTransferEvent } from "./hooks/useListenTransferEvent";
import { useTransferNft } from "./hooks/useTransferNft";
import "./index.less";
import { LoadingOutlined } from "@ant-design/icons";
import CheckIcon from "../../components/Icons/CheckIcon";
import { useScreenWidth } from "../../Hook/useScreenWith";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { CLAIM_EVENT_CLEAR } from "../../actions/types";
const { Text } = Typography;

const HERON_NFT_ADDRESS = getAddress(
  process.env.REACT_APP_HERON_NFT_ADDRESS + ""
);
const HERON_NFT_2023_ADDRESS = getAddress(
  process.env.REACT_APP_HERON_NFT_2023_ADDRESS + ""
);
const antIcon = (
  <LoadingOutlined style={{ fontSize: 16, color: "#ffffff" }} spin />
);

export default function HeronNft2023() {
  const { account } = useWeb3React();
  const [listClaimNft, setListClaimNft] = useState<any[]>([]);
  const { claimEvent } = useListenClaimEvent(HERON_NFT_2023_ADDRESS);
  const { transferEvent } = useListenTransferEvent(HERON_NFT_2023_ADDRESS);
  // const { data: listTokenOfHeronNft, isLoading: isListTokenOfHeronNftLoading } = useGetListTokenOfNft(HERON_NFT_ADDRESS);
  // const { data: listTokenOfHeron2023nft, isLoading: isListTokenOfHeron2023NftLoading } = useGetListTokenOfNft2023(HERON_NFT_2023_ADDRESS);
  const t = useGetListTokenOfNft2023SwrInfinite({
    tokenAddress: HERON_NFT_2023_ADDRESS,
  });

  const claimEventList = useSelector((state: any) => state.claim.data);
  const transferEventList = useSelector((state: any) => state.transfer.list);

  const {
    data: infiniteDataNft2023,
    size: sizeNft2023,
    mutate: mutateNft2023,
    setSize: setSizeNft2023,
    isLoadingMore: isLoadingMoreNft2023,
    isReachingEnd: isReachingEndNft2023,
    isRefreshing: isRefreshingNft2023,
  } = t;

  useEffect(() => {
    mutateNft();
    mutateNft2023();
  }, [account]);

  useEffect(() => {
    mutateNft();
  }, [JSON.stringify(transferEvent)]);

  // const { claimInfo, isClaimInfoLoading } = useMulticallClaimInfo(listTokenOfHeronNft.map((item: any) => item.token_id));
  const n = useGetListTokenOfNftSwrInfinite({
    tokenAddress: HERON_NFT_ADDRESS,
  });

  const {
    data: claimInfo,
    size: sizeNft,
    mutate: mutateNft,
    setSize: setSizeNft,
    isLoadingMore: isLoadingMoreNft,
    isReachingEnd: isReachingEndNft,
    isRefreshing: isRefreshingNft,
  } = n;

  const listTokenNftWithStatus = useMemo(() => {
    const listTokenIdClaimed = claimEventList.map((item: any) =>
      item.tokenId.toNumber()
    );
    const listTokenIdTransfer = transferEventList.map((item: any) =>
      item.tokenId.toNumber()
    );

    const res = claimInfo.map((item: any) => {
      if (listTokenIdClaimed.includes(Number(item.token_id))) {
        return {
          ...item,
          status: "claimed",
        };
      }
      if (listTokenIdTransfer.includes(Number(item.token_id))) {
        return {
          ...item,
          status: "claimed",
        };
      }
      return {
        ...item,
        status: item.isClaimed ? "claimed" : "not claimed",
      };
    });
    return res;
  }, [claimInfo, claimEventList, transferEventList]);

  const listHeronNft2023 = useMemo(() => {
    let res = infiniteDataNft2023.map((item: any) => {
      return {
        token_id: item.token_id,
      };
    });
    if (claimEventList) {
      const listClaimed = claimEventList.map((item: any) => {
        return {
          token_id: item.tokenId.toNumber(),
        };
      });
      res.unshift(...listClaimed.reverse());
    }

    if (transferEvent) {
      res = res.filter((item: any) => {
        if (
          item.token_id == transferEvent.tokenId.toNumber() &&
          account?.toLowerCase() == transferEvent.from.toLowerCase()
        ) {
          return false;
        }
        return true;
      });
    }

    res = res.filter((item: any, index: number) => {
      return (
        res.findIndex((item2: any) => item2.token_id == item.token_id) == index
      );
    });

    return res;

    // }, [listTokenOfHeron2023nft, claimEventList, transferEvent]);
  }, [infiniteDataNft2023, claimEventList]);

  // const screenWith = useScreenWidth();

  return (
    <Layout.Content
      style={{
        gap: 40,
        paddingTop: 64,
        paddingBottom: 64,
      }}
    >
      <div className="layout-wrapper">
        <div className="item">
          <div className="item-layout">
            <div className="item-header">Heron NFTs</div>
            <div className="item-content">
              <div className="content-header">
                <div>Heron NTFs ID</div>
                <div>Status</div>
              </div>
              <div className="content-table">
                {claimInfo.length === 0 &&
                  !isLoadingMoreNft &&
                  !isRefreshingNft && (
                    <div className="no-data">
                      Your Heron NFTs will be showed here.!
                    </div>
                  )}
                {isRefreshingNft ? (
                  <Spin size="large" />
                ) : (
                  listTokenNftWithStatus.map((item: any) => {
                    return (
                      <ClaimInfoRow
                        key={`heron-nft-status-${item.token_id}`}
                        item={item}
                        listClaimNft={listClaimNft}
                        setListClaimNft={setListClaimNft}
                        claimInfo={claimInfo}
                        claimEvent={claimEvent}
                        claimEventList={claimEventList}
                      />
                    );
                  })
                )}
              </div>
              {claimInfo.length > 0 && (
                <InView
                  onChange={(inView) => {
                    if (inView && !isReachingEndNft && !isLoadingMoreNft) {
                      setSizeNft(sizeNft + 1);
                    }
                  }}
                />
              )}
              {account && isLoadingMoreNft && <Spin size="large" />}
            </div>
          </div>
        </div>
        <div className="item">
          <div className="item-layout">
            <div className="item-header">Heron NFTs 2025</div>
            <div className="item-content">
              <div className="content-header">
                <div>Heron NFTs 2025 ID</div>
                <div>Action</div>
              </div>
              <div className="content-table">
                {listHeronNft2023.length === 0 &&
                  !isLoadingMoreNft2023 &&
                  !isRefreshingNft2023 && (
                    <div className="no-data">
                      Your Heron NFTs 2025 will be showed here.!
                    </div>
                  )}
                {isRefreshingNft2023 ? (
                  <Spin size="large" />
                ) : (
                  listHeronNft2023.map((item: any) => {
                    return (
                      <TransferBox
                        key={`heron-nft-2025-${item.token_id}`}
                        item={item}
                      />
                    );
                  })
                )}
              </div>
              {listHeronNft2023.length > 0 && (
                <InView
                  onChange={(inView) => {
                    if (
                      inView &&
                      !isReachingEndNft2023 &&
                      !isLoadingMoreNft2023
                    ) {
                      setSizeNft2023(sizeNft2023 + 1);
                    }
                  }}
                />
              )}
              {account && isLoadingMoreNft2023 && <Spin size="large" />}
            </div>
          </div>
        </div>
      </div>
    </Layout.Content>
  );
}

function ClaimInfoRow({
  item,
  listClaimNft,
  setListClaimNft,
  claimInfo,
  claimEvent,
  claimEventList,
}: {
  item: any;
  listClaimNft: any[];
  setListClaimNft: React.Dispatch<React.SetStateAction<any[]>>;
  claimInfo: any[];

  claimEvent: any;
  claimEventList: any[];
}) {
  const { claim, isClaiming } = useClaimNft(
    HERON_NFT_2023_ADDRESS,
    listClaimNft,
    setListClaimNft
  );
  // const claimState = useMemo(() => {

  //   const claimEventItem = claimEventList.find((itemClaim: any) => itemClaim.tokenId.toNumber() == item.token_id);

  //   if (claimEventItem) {
  //     return true;
  //   }

  //   const claimInfoItem = claimInfo.find((itemClaim: any) => itemClaim.token_id == item.token_id);
  //   if (claimInfoItem) {
  //     if (claimInfoItem.isClaimed) {
  //       return true;
  //     }
  //     return false
  //   }
  //   return false;
  // }, [isClaimInfoLoading, claimInfo, item, claimEventList]);

  return (
    <div className="table-item" key={item.token_id}>
      <div className="table-item-label">{item.token_id}</div>
      {/* {isClaimInfoLoading ? <Spin size="small" indicator={antIcon} /> : <>
        {claimState ?
          <div className="table-item-value">Claimed</div>
          : <button className="table-item-button" onClick={() => { claim(item.token_id); }} disabled={isClaiming}>Claim
            {isClaiming && <>{" "} <Spin size="small" indicator={antIcon} /></>}
          </button>}
      </>} */}

      {item.status == "claimed" && (
        <div className="table-item-value">Claimed</div>
      )}

      {item.status == "not claimed" && (
        <button
          className="table-item-button"
          onClick={() => {
            claim(item.token_id);
          }}
          disabled={isClaiming}
        >
          Claim
          {isClaiming && (
            <>
              {" "}
              <Spin size="small" indicator={antIcon} />
            </>
          )}
        </button>
      )}
    </div>
  );
}

function TransferBox({ item }: { item: any }) {
  const { transfer, isTransferring } = useTransferNft(HERON_NFT_2023_ADDRESS);

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };
  return (
    <div className="table-item" key={item.token_id}>
      <div className="table-item-label">{item.token_id}</div>
      <button
        className="table-item-button"
        onClick={showModal}
        disabled={isTransferring}
      >
        Transfer
        {isTransferring && (
          <>
            {" "}
            <Spin size="small" indicator={antIcon} />
          </>
        )}
      </button>
      <Modal
        visible={open}
        onOk={hideModal}
        onCancel={hideModal}
        centered
        className=""
        destroyOnClose={true}
      >
        <TransferModal
          tokenId={item.token_id}
          transfer={transfer}
          isTransferring={isTransferring}
          open={open}
        />
      </Modal>
    </div>
  );
}

function TransferModal({
  tokenId,
  transfer,
  isTransferring,
  open,
}: {
  tokenId: string;
  transfer: any;
  isTransferring: boolean;
  open: boolean;
}) {
  const [address, setAddress] = useState<string>("");

  return (
    <div className="transfer-modal">
      <div className="hero-header">Transfer</div>
      <div className="input-box">
        <div className="input-label">Receive address</div>
        <Input
          placeholder="Enter address"
          className="input-address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          suffix={isAddress(address) ? <CheckIcon /> : null}
          style={{
            border: isAddress(address)
              ? "2px solid #58C27D"
              : address
              ? "2px solid #FF4D4F"
              : "2px solid #d9d9d9",
          }}
        />
      </div>

      <button
        className="transfer-button"
        onClick={() => {
          transfer({
            to: address,
            tokenId: Number(tokenId),
          });
        }}
        disabled={isTransferring}
      >
        Transfer
        {isTransferring && (
          <>
            {" "}
            <Spin size="small" indicator={antIcon} />
          </>
        )}
      </button>
    </div>
  );
}
