import { useWeb3React } from "@web3-react/core";
import { Button, Dropdown, Layout, Menu, Modal, Space, Drawer } from "antd";
import { useCallback, useEffect, useState } from "react";
import logo from "../logo.png";
import logoMobile from "../logo_mobile.png";
import { CONNECTED } from "../constants/index";
import { useNavigate, useMatch } from "react-router-dom";
import { useScreenWidth } from "../Hook/useScreenWith";
import { MenuOutlined } from "@ant-design/icons";

const ClaimHeader =
  process.env.REACT_APP_HEADER_TOKEN_NAME || "Claim Heron NFTs";

const NavigateBarMobile = () => {
  const [open, setOpen] = useState(false);

  const screenWith = useScreenWidth();
  const navigate = useNavigate();

  const isHome = useMatch("/");
  const isSwapPage = useMatch("/swap");
  const isNFT2023 = useMatch("/heron-nft-2025");

  useEffect(() => {
    if (screenWith > 768) {
      setOpen(false);
    }
  }, [screenWith]);

  const showMenu = () => {
    setOpen(true);
  };

  const hideMenu = () => {
    setOpen(false);
  };
  return (
    <div>
      <div
        onClick={showMenu}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MenuOutlined style={{ fontSize: 24 }} />
      </div>

      <Drawer
        visible={open}
        onClose={hideMenu}
        placement="right"
        closable={false}
        key="right"
        width={200}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            padding: 16,
          }}
        >
          <Button
            type="link"
            onClick={() => navigate("/")}
            style={isHome ? LinkActiveStyleMobile : LinkNotActiveStyleMobile}
          >
            Booking
          </Button>

          <Button
            type="link"
            onClick={() => navigate("/swap")}
            style={
              isSwapPage ? LinkActiveStyleMobile : LinkNotActiveStyleMobile
            }
          >
            Swap
          </Button>
          <Button
            type="link"
            onClick={() => navigate("/heron-nft-2025")}
            style={isNFT2023 ? LinkActiveStyleMobile : LinkNotActiveStyleMobile}
          >
            {ClaimHeader}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

const Header = (props: any) => {
  const { account, active, deactivate, chainId } = useWeb3React();
  const handleDisconnect = useCallback(() => {
    localStorage.removeItem(CONNECTED);
    deactivate();
  }, [deactivate]);
  const navigate = useNavigate();
  const navigateToMyVouchers = useCallback(() => {
    navigate("/vouchers");
  }, []);

  const isHome = useMatch("/");
  const isSwapPage = useMatch("/swap");
  const isNFT2023 = useMatch("/heron-nft-2025");
  const screenWith = useScreenWidth();

  const renderContent = useCallback(() => {
    if (!active) {
      return (
        <Button onClick={props.openWeb3Modal} shape="round" type="primary">
          Connect Wallet
        </Button>
      );
    }
    return (
      <Dropdown
        arrow
        overlay={
          <Menu>
            <Menu.Item onClick={navigateToMyVouchers}>My vouchers</Menu.Item>
            <Menu.Item
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Clear cache
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                handleDisconnect();
              }}
            >
              Disconnect
            </Menu.Item>
          </Menu>
        }
      >
        <Button shape="round">
          {account ? account.substr(0, 2) + "..." + account.substr(-4) : ""}
        </Button>
      </Dropdown>
    );
  }, [account, active, chainId]);

  return (
    <Layout.Header
      style={
        screenWith < 768
          ? {
              padding: "16px 16px",
            }
          : {}
      }
    >
      {screenWith < 768 ? (
        <img
          src={logoMobile}
          alt="logo"
          style={{
            height: 64,
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      ) : (
        <div
          style={{
            backgroundImage: `url(${logo})`,
            height: 64,
            width: 152,
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      )}
      {process.env.REACT_APP_NFT && (
        <>
          {screenWith > 768 && (
            <>
              <Button
                type="link"
                onClick={() => navigate("/")}
                style={isHome ? LinkActiveStyle : LinkNotActiveStyle}
              >
                Booking
              </Button>

              <Button
                type="link"
                onClick={() => navigate("/swap")}
                style={isSwapPage ? LinkActiveStyle : LinkNotActiveStyle}
              >
                Swap
              </Button>
              <Button
                type="link"
                onClick={() => navigate("/heron-nft-2025")}
                style={isNFT2023 ? LinkActiveStyle : LinkNotActiveStyle}
              >
                {ClaimHeader}
              </Button>
            </>
          )}
          <div
            style={{
              display: "flex",
              gap: 16,
              alignItems: "center",
            }}
          >
            {renderContent()}
            {screenWith < 768 && <NavigateBarMobile />}
          </div>
        </>
      )}
    </Layout.Header>
  );
};

export default Header;

const LinkActiveStyle = {
  borderBottom: "2px solid #329696",
  fontWeight: 700,
};

const LinkNotActiveStyle = {
  color: "#777E91",
  fontWeight: 700,
};

const LinkActiveStyleMobile = {
  borderBottom: "2px solid #329696",
  fontWeight: 700,
};

const LinkNotActiveStyleMobile = {
  color: "#777E91",
  fontWeight: 700,
};
